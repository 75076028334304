import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes,  Navigate} from 'react-router-dom';
import './index.css';
//import App from './App';
import Header from './header';
import * as serviceWorker from './serviceWorker';
import {Page} from "./modules/omwui/index";
import {Home} from "./public/home"

const helper = require("./helper");



function Logout() {

  helper.postToServer("/logout")
  .then(res => {
    sessionStorage.clear();
    document.location = "/"

  })
  .catch(err => {
    helper.showError();
  })
  
  }


function checkName()
  {
    if (sessionStorage.getItem("name"))
      return true;
    sessionStorage.setItem("rd", document.location)
    return false;
  }


/*
const PrivateRoute = ({ component: Component, ...rest }) => 
(
 <Route {...rest} render={(props) => (

	  checkName()	? <Component {...props} />
			: <Navigate to='/login' />
  )
} />
)
*/


function PrivateRoute(props) {
  const auth = checkName();
  return auth ? <><props.component/></> : <Navigate to="/login" />;
}

const Element1 = (props) => {
  return <><props.component/></>
}


const App = () => (
  <BrowserRouter>
    <Header/>
    <Page>
     <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route exact path="/" element={<Element1 component={Home}/>}/>
        <Route path="/home" element={<Element1 component={Home}/>}/>
        <Route path="/gen" element={<Element1 component={require("./public/gen").default}/>}/>
        <Route path="/gen_web" element={<Element1 component={require("./public/gen_web").default}/>}/>
        <Route path="/login" element={<Element1 component={require("./login").default}/>}/>
        <Route path="/logout" element={<Element1 component={Logout}/>}/>
        <Route path="/rpass1" element={<Element1 component={require("./reset-pass-1").default}/>}/>
        <Route path="/rpass2" element={<Element1 component={require("./reset-pass-2").default}/>}/>

        <Route path="/admin/users" element={<PrivateRoute component={lazy(() => import('./admin/list_users'))}/>}/>
        <Route path="/admin/invoices" element={<PrivateRoute component={lazy(() => import('./admin/list_invoices'))}/>}/>
        <Route path="/admin/invlines" element={<PrivateRoute component={lazy(() => import('./admin/list_invlines'))}/>}/>
        <Route path="/admin/print_invoice" element={<PrivateRoute component={lazy(() => import('./admin/print_invoice'))}/>}/>

        <Route path="/user/projects" element={<PrivateRoute component={lazy(() => import('./user/list_projects'))}/>}/>
        <Route path="/user/tasks" element={<PrivateRoute component={lazy(() => import('./user/list_tasks'))}/>}/>
        <Route path="/user/todo" element={<PrivateRoute component={lazy(() => import('./user/list_todo'))}/>}/>
        <Route path="/user/wiki" element={<PrivateRoute component={lazy(() => import('./user/form_wiki'))}/>}/>
        <Route path="/user/wedit" element={<PrivateRoute component={lazy(() => import('./user/form_wedit'))}/>}/>
        <Route path="/user/timesheet" element={<PrivateRoute component={lazy(() => import('./user/timesheet'))}/>}/>

      </Routes>
    </Suspense>
    </Page>
  </BrowserRouter>
);


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
