import React, {useState} from 'react';
import {Section} from "../modules/omwui/index";
import {ButtonList} from "../modules/omwui/misc";

const helper = require("../helper")

var g_rows = [];

//var colTemplate = 

export default () => {

  var [gen, setGen] = useState('')

  return (
    <div style={{margin: "20px"}}>
      <Section>

<div style={{float:"left"}}>
Web generator<br/><br/>
<table>
<tbody>
<tr><td># of columns:</td><td><input type="text" id="n_cols" /></td></tr>
<tr><td># of rows:</td><td><input type="text" id="n_rows" /></td></tr>
</tbody>
</table>
<br/>
<button onClick={() => {

  var rows = [];
  var nRows = `${document.getElementById("n_cols").value}`
  for (var i = 0; i < nRows; i++) {

    rows.push(<div key={i}>{`row${i}`}</div>)

  }
  gen = <div>{rows}</div>

  setGen(gen);

}}>Preview</button>
</div>
<div style={{float: "left", margin: "30px"}}>
{gen}
</div>
      </Section>
    </div>
  )
}