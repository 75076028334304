import React from 'react';
import {Section} from "../modules/omwui/index";
import {ButtonList} from "../modules/omwui/misc";
import {templates} from "./gen";

export const Home = () => {
  return (
    <div>
      <Section>
        Welcome<br/><br/>
      Generators: <br/>
    <ButtonList list={templates} title="Gen" onClick={(id) => {
      document.location = "/gen_"+id;
    }}/>
      </Section>
      <Section>
      </Section>
    </div>
  )
}