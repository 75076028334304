import React, { Component } from "react";

const helper = require("./helper");

class ResetPass1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }

  onSubmitHandler(event) {
    event.preventDefault();

    helper.postToServer("/rpass1", { email: this.state.email }).then((res) => {
//      console.log(res);
      alert(res.result);
    });
  }

  render() {
    return (
      <div className="App">
        <form onSubmit={this.onSubmitHandler.bind(this)}>
          <label>Enter Email:</label>
          <input
            type="text"
            name="emailBox"
            value={this.state.email}
            placeholder="you@example.com"
            onChange={(event) => this.setState({ email: event.target.value })}
          />
          <input type="submit" value="Submit" />
        </form>
      </div>
    );
  }
}

export default ResetPass1;
