import React, { Component } from "react";
//import {Drawer, Section, Page} from "omwui";

const config = require("./config.js");

class Header extends Component {
  showMenu() {
    let admin = "";
    let login = (
      <a href="/login" className="w3-bar-item w3-button">
        Login
      </a>
    );
    let user = "";

    //  <a href="/user/ctypes" className="w3-bar-item w3-button">CTypes</a>

    if (sessionStorage.getItem("name")) {
      login = (
        <a href="/logout" className="w3-bar-item w3-button">
          Logout
        </a>
      );
      user = (
        <>
          <div className="w3-dropdown-hover">
            <button className="w3-button">Lists</button>
            <div className="w3-dropdown-content w3-bar-block w3-border">
              <a href="/user/projects" className="w3-bar-item w3-button">
                Projects
              </a>
              <a href="/user/tasks" className="w3-bar-item w3-button">
                Tasks
              </a>
              <a href="/user/todo" className="w3-bar-item w3-button">
                TODO
              </a>
            </div>
          </div>

          <div className="w3-dropdown-hover">
            <button className="w3-button">Time</button>
            <div className="w3-dropdown-content w3-bar-block w3-border">
              <a href="/user/timesheet" className="w3-bar-item w3-button">
                Timesheet
              </a>
              <a href="/user/expense" className="w3-bar-item w3-button">
                Expenses
              </a>
            </div>
          </div>
        </>
      );
    }

    if (sessionStorage.getItem("admin")) {
      admin = (
        <div className="w3-dropdown-hover">
          <button className="w3-button">Admin</button>
          <div className="w3-dropdown-content w3-bar-block w3-border">
            <a href="/admin/users" className="w3-bar-item w3-button">
              Users
            </a>
            <a href="/admin/time" className="w3-bar-item w3-button">
              Time
            </a>
            <a href="/admin/invoices" className="w3-bar-item w3-button">
              Invoices
            </a>
            <a href="/api/admin/logs" className="w3-bar-item w3-button">
              Logs
            </a>
          </div>
        </div>
      );
    }
    return (
      <div className="w3-bar w3-green">
        <a href="/home" className="w3-bar-item w3-button">
          Home
        </a>
        {user}
        {admin}
        {/*
      <a href="/about" className="w3-bar-item w3-button">About</a>
      <a href="/contact" className="w3-bar-item w3-button">Contact</a>
*/}
        {login}
      </div>
    );
  }

  componentDidMount() {
    document.title = config.name;
    //console.log(config.back);
    //  fetch(config.back+'/alog?u='+encodeURIComponent(window.location.href));
  }
  render() {

    return (
      <div>
        <div className="w3-container w3-teal">
          <div style={{ maxWidth: "800px" }}>
            <h1>{config.name}</h1>
            <h5>{sessionStorage.getItem("name")}</h5>
            Omnicode
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          {this.showMenu()}
        </div>
      </div>
    );
  }
}

export default Header;
