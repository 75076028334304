import React from 'react';

const config = require('./config.js');
const helper = require("./helper");


function finishLogin(response)
{
// OE!!! This is where to put the TOS agreement!!!!
// check response.tos

	if (response.result === "OK")
	{

//    sessionStorage.setItem("connection", cookies.get("connect.sid"))
		sessionStorage.setItem("name", response.name)
    sessionStorage.setItem("uid", response.uid)
    if (response.roles)
    {
      if (response.roles.indexOf("admin") !== -1)
        sessionStorage.setItem("admin", true)
    }

//		this.setState({error: "Worked!"});
    if (sessionStorage.getItem("rd"))
//      history.replace(sessionStorage.getItem("rd"))
      document.location = sessionStorage.getItem("rd");
    else
      document.location = "/user"
//      history.replace('/home')

    sessionStorage.removeItem("rd")

	}
	else
	{
		sessionStorage.setItem("name", null)
		this.setState({error: "Sorry!"});
	}


}


class  Login extends React.Component {

  constructor()
  {
    super();


    this.state = {
      email: "",
      password: "",
	error: ""
    };

    this.handleSubmit = this.handleSubmit.bind(this);


  }


handleSubmit(event) {
    event.preventDefault();


  helper.postToServer('/login', {
    user: event.target.elements.email.value,
    password: event.target.elements.password.value,
  }).then(res => 
{
  finishLogin(res)

});


}

 
  render() {
    document.title = "Login"
 return(
<div className="w3-container">
<br/>
<form  onSubmit={this.handleSubmit}>
<div className="col-md-6">
	<input type="text" name="email" className="form-control" id="email" placeholder="you@example.com" autoFocus/><br/><br/>
	<input type="password" name="password" className="form-control" id="password" placeholder="Password"/><br/><br/>

{this.state.error}
  <button type="submit" className="btn btn-primary">Login</button>
</div>

</form>
<br/>

</div>
 
    );
}
 }

export default Login;

