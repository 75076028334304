import React, { Component } from "react";


export const Space = (props) => {
      /// OE??!!! Just a space
              return(
                  <>&nbsp;</>
              )
          }
          

  
export const Pager = (props) => {

        var nPages = props.nRows/props.pageSize
        var o = Number(props.offset)
        var p = o-props.pageSize
        if (p < 0)
            p = 0
        var n = o+props.pageSize
        if (n > props.nRows-1)
            n = props.nRows-1
        if (nPages > 10)
          nPages = 10; 

    
        var middle = []
        for (var i = 0; i < nPages; i++)
        {
            if (i*props.pageSize != props.offset)
                middle.push(<a key={i} className="w3-button" href={props.link+"o="+i*props.pageSize}>{i+1}</a>)
            else
                middle.push(<a key={i} className="w3-button" style={{color: "red"}} href={props.link+"o="+i*props.pageSize}>{i+1}</a>)

        }
    
        return(
        <div className="w3-bar">
        <a className="w3-button" href={props.link+"o="+p}>Previous</a>
        {middle}
        <a className="w3-button" href={props.link+"o="+n}>Next</a>
      </div>
        )
    
    }
    
export const UrlSelector = (props) => {


    var links = [];
    for (var i = 0; i < props.links.length; i+= 2) {
        links.push(<a style={{color: "red"}} href={props.links[i+1]}>{props.links[i]}</a>);
        links.push(<>&nbsp;</>);
    }

    return (

        <>{links}</>
    )

}
    

export const ButtonList = (props) => {

    var list = [];
    var i = 0;
    props.list.map((item) => {
        list.push(<div key={i++}>{item.name} <button type="button" onClick={() => props.onClick(item.id)}>{props.title}</button></div>)
    })
    return <div>{list}</div>
}