import React from 'react';
//import { config } from './config.js';

const config = require('./config.js');

export function parseQuery(queryString ) 
{
	if (!queryString)
		queryString = document.location.search;

    queryString = queryString.substring(1 );

    var params = {}, queries, temp, i;
    // Split into key/value pairs
    queries = queryString.split("&");

    // Convert the array of strings into an object
    for ( i = 0; i < queries.length; i++)  
   {
        temp = queries[i].split('=');
        params[temp[0]] = temp[1];
    }
    return params;
}


export function callUrl(l)
{
    window.location = '/'+l;
}

export function callUrl1(l)
{
    window.location = l;
}


function nodify(inputText) 
{

// OE??!! this can probably be done with one step.  No need to create a node and then step the tree 2018-8-8
// ![alt text](https://github.com/adam-p/markdown-here/raw/master/src/common/images/icon48.png "Logo Title Text 1")

//var re = new RegExp('\\w+\\s', 'g');
	var node = new Node("span", "");
	var root = node
//	var re = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
	var re = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#%?=~_|!:,.;]*[-A-Z0-9+&@#%=~_|])/gim;

	var xArray; 
	var lastIndex = 0
//	while(xArray = re.exec(inputText)) 
	while((xArray = re.exec(inputText))) 
	{
		var node1 = new Node("href", xArray[0]);
		node.data = inputText.substr(lastIndex, xArray.index-lastIndex)
		node.next = node1;
		node = node1
		lastIndex = xArray.index+xArray[0].length;
		if (lastIndex < inputText.length)
		{
//			console.log("adding 2nd node z" + inputText.substring(lastIndex))
			var node2 = new Node("span", "")
			node.next = node2;
			node = node2
		}
	}
	if (node.type !== "href" && lastIndex < inputText.length)
		node.data = inputText.substr(lastIndex)
	return root


}


function Node(type, data) 
{
	this.data = data;
	this.type = type;
	this.next = null;
	this.log = function()
	{
		console.log(this.type + ": " + this.data)
		if (this.next != null)
			this.next.log()
	}
}
// Use snarkdown!!!
// https://github.com/developit/snarkdown
export function textToJsx(body)
{
	var r = []
//	r.push("div")

	if (body === null)
		return r;

	var root = new Node("root", "")
	var node = root;
	var start = 0
	var i = 0
	while ((i = body.indexOf("\n", start)) !== -1)
	{
		node.next = nodify(body.substring(start, i));
		node = node.next;
		node.next = new Node("br", "br");
		node = node.next;
		start = i+1
	}
	i = body.length
	if (i >= start)
	{
//		node.next = new Node("span", body.substring(start, i));
		node.next = nodify(body.substring(start, i));

	} 
// OE???!!! I don't think these sections should be seperate!!!!
	node = root.next;
	while (node != null)
	{
		if (node.type === "span")
		{
			if (node.data !== "")
				r.push(node.data)
		}
		else if (node.type === "br")
		{
			r.push(<br/>)
		}
		else if (node.type === "href")
		{
			r.push(<a href={node.data}>{node.data}</a>)
		}
		else if (node.type === "img")
		{
// image always has a link
			r.push(<a href={node.url}><img src={node.data} alt={node.alt}/></a>)
}
		node = node.next;
	}

//	r.push(["span", "one"])
//	r.push(["span", "three"])

//	return ["div", ["span", "one "], ["span", "two"]];
/*	var r1 = []
	r1.push("Hello")
	r = [] 
	r.push(<div>{r1}</div>) */

	return <div>{r}</div>;
}

export function checkNull(s)
{
	if (s === null)
		return ""

	if (s === undefined)
		return ""

	return s

}

export function postToServer(link, data) {
// doesn't really do much except abstract fetch
// and give a fetch/Promise example

	let p1 = new Promise((resolve,reject) => {
	fetch(config.back+link, {
	  method: 'POST', credentials: "same-origin",
	  headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	  },
	  body: JSON.stringify(data),
	}).then(res => res.json())
	.then(function(response) { 
		if (response.code == 201)
		{
			throw (response.result)
		}

		resolve(response)
	})
	.catch(error => {
		if (error.detail) //??OE!! Fix this!! it should be handled at the server side!
			error = error.detail;

		reject(error)})
	})
	
	return p1
  }

  export function getFromServer(link) {
// doesn't really do much except abstract fetch
// and give a fetch/Promise example

	let p1 = new Promise((resolve,reject) => {
		fetch(config.back+link)
	.then(res => res.json())
	.then(function(response) { 
		if (response.code == 201)
		{
			throw (response.result)
		}
		else if (response.code == 202)
		{
			sessionStorage.clear();
      document.location = "/login"
			
	}

		resolve(response)
	})
	.catch(error => 
		reject(error))
	})
  
	return p1

  }
  
export  const modelStyles = {
	content : {
	  top                   : '50%',
	  left                  : '50%',
	  right                 : 'auto',
	  bottom                : 'auto',
	  marginRight           : '-50%',
	  transform             : 'translate(-50%, -50%)'
	}
  };
  
  function getLinkArray(text)
  {
	  if (!text)
	  	return text;
	var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
	var exp1 = exp.exec(text);
	if (exp1 == null)
		return text;
//	console.log(exp1);
	var a = [];
	a.push(text.substring(0, exp1.index));
	a.push(<a href={exp1[0]}>{exp1[1]}</a>);
	a.push(text.substring(exp1.index+exp1[0].length));
	return a;
//	text.replace(exp,"<a href='$1'>$1</a>"); 

//	return text;
  }

 export function getMarkdown(body)
 {
	if (!body)
		return [];

	

	var lines = body.split(/\r?\n/);

	var a = [];

	for (var i = 0; i < lines.length; i++)
	{
		a.push(getLinkArray(lines[i]));
		a.push(<br/>);
	}

	return a;

 }

 export function getURI(uri)
 {

	if (!uri)
		return "";
	return encodeURI(uri);
 }

 export function currency(x)
{
/*	var n = 2;
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
	x = x.toFixed(2); */
	return x.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function comma(x, n)
{
/*	var n = 2;
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
	x = x.toFixed(2); */
	if (!n)
		n = 2;
	x = Number(x)
	return x.toFixed(n).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function preMarkdown(body, project)
{
	var ret = "";
	var re = /\[.*?\]/g;
	var m = re.exec(body);
	if (m)
	{
		var len = body.length;
		var lastIndex = 0;
		do 
		{
			if (lastIndex != m.index)
				ret += body.substring(lastIndex, m.index);
			var name = body.substring(m.index+1, re.lastIndex-1);
			var link = "/user/wiki?dw=get&project="+project+"&name="+getURI(name);
			console.log(link);
			ret += `[${name}](${link})`;
			lastIndex = re.lastIndex;

		}
		while ((m = re.exec(body)) !== null);
		if (len > lastIndex)
			ret += body.substring(lastIndex, len);
	}
	else
	{
		ret = body;
	}
	return ret;
}
export function renderWiki(body, project)
{
	body = body.replace("\r", "");
	
//	/\n{2,}/g   ???

	var a = body.split(/\n/);
	var ren = [];

	for (var i = 0; i < a.length; i++)
	{
	  var re = /\[.*?\]/g;
	  var m = re.exec(a[i]);
	  if (m)
	  {
		  var len = a[i].length;
		  var lastIndex = 0;
		  do 
		  {
			  if (lastIndex != m.index)
				  ren.push(a[i].substring(lastIndex, m.index));
			  var name = a[i].substring(m.index+1, re.lastIndex-1);
			  var link = "/user/wiki?dw=get&project="+project+"&name="+getURI(name);
			  ren.push(<a href={link}>{name}</a>);
			  lastIndex = re.lastIndex;

		  }
		  while ((m = re.exec(a[i])) !== null);
		  if (len > lastIndex)
			  ren.push(a[i].substring(lastIndex, len));

/*
		  console.log(m);
		  for (var j = 0; j < m.length; j++)
		  {
			  ren.push(m[j].input)
		  }
*/			
	  }
	  else
	  {
		  ren.push(a[i]);
	  }
	  ren.push(<br/>);
	}

//	var re = /[\n]+/gim;

//	ren.push("Hello");
//	ren.push(<br/>);
//	ren.push("there!");



//	console.log(re.exec(body));

/*	var xArray; 
  var lastIndex = 0
  while((xArray = re.exec(body.substring(lastIndex)))) 
  {
	  ren.push(body.substring(lastIndex, lastIndex+xArray.index));
	  ren.push(<br/>);
	  lastIndex = lastIndex+xArray.index+xArray[0].length;
  }
*/
  return ren;

}

export function handleError(err)
{
	alert(err);
}