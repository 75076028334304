import React from 'react';
import {Section} from "../modules/omwui/index";
import {ButtonList} from "../modules/omwui/misc";

const helper = require("../helper")


/*
Todo:
1. Make better ButtonList


1. Web templates types
a) Restaurant
b) Service Company
c) Retail
d) Free form

2. Web template platforms
a) React/Node
b) Flutter/Node
c) Static html
d) Php?


3. Machine learning models:
a) Simple classification
b) Simple regression
c) Image classification
d) 

4. Machine learing platforms:
a) Tensorflow Python
b) Tensorflow Javascript
c) Tensorflow Lite

5. Logo types:
a) Look in Gimp!!!




*/

export const templates = [{id: "web", name: "Website"}, 
    {id: "ml", name: "Machine Learning Model"}, 
    {id: "logo", name: "Logo"}];

export default () => {
    var q = helper.parseQuery();
    console.log(q);

  return (
    <div>
      <Section>
      Generator: {q.id} <br/>
      </Section>
      <Section>
      </Section>
    </div>
  )
}